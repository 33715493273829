import * as Sentry from '@sentry/vue';
import Axios from 'axios';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { defineAsyncComponent, createApp } from 'vue';
import { HeartIcon } from '@heroicons/vue/24/outline';
import { LaravelRoutes, setLaravelRoutes } from './shared/helpers/routes';
import translate from './shared/helpers/translate';
import { lazyLoad } from './shared/directives/lazy-load.directive';
import { scrollEnd } from './shared/directives/scroll-end.directive';

/**
 * Async Components
 *
 * Need skeletons to be loaded before the app is mounted
 *
 * @see https://vuejs.org/guide/components/async
 */
const TheSearch = defineAsyncComponent(() => import('./components/search/TheSearch.vue'));
const FidelityPointsUsagePopover = defineAsyncComponent(() => import('./components/customer/fidelityPointsUsagePopover.vue'));
const TheCheckoutContainer = defineAsyncComponent(() => import('./components/checkout/TheCheckoutContainer.vue'));
const ThePaymentReturn = defineAsyncComponent(() => import('./components/checkout/return/ThePaymentReturn.vue'));
const ProForm = defineAsyncComponent(() => import('./components/pro-form.vue'));
const VImg = defineAsyncComponent(() => import('./components/common/VImg.vue'));
const Alert = defineAsyncComponent(() => import('./components/common/alert.vue'));
const Slider = defineAsyncComponent(() => import('./components/common/slider.vue'));
const HelpIcon = defineAsyncComponent(() => import('./components/common/helpIcon.vue'));
const BtnSubmit = defineAsyncComponent(() => import('./components/common/btnSubmit.vue'));
const InputError = defineAsyncComponent(() => import('./components/common/inputError.vue'));
const Breadcrumb = defineAsyncComponent(() => import('./components/common/breadcrumb.vue'));
const UserIconLogin = defineAsyncComponent(() => import('./components/common/userIconLogin.vue'));
const MarketingInfos = defineAsyncComponent(() => import('./components/common/marketingInfos.vue'));
const RecentlyConsultedContainer = defineAsyncComponent(() => import('./components/common/recentlyConsultedContainer.vue'));
const SimpleList = defineAsyncComponent(() => import('./components/footer/simpleList.vue'));
const Disclosure = defineAsyncComponent(() => import('./components/footer/disclosure.vue'));
const ShopsDropdown = defineAsyncComponent(() => import('./components/footer/shopsDropdown.vue'));
const NewsletterEmail = defineAsyncComponent(() => import('./components/footer/newsletterEmail.vue'));
const TheMainMenu = defineAsyncComponent(() => import('./components/menu/TheMainMenu.vue'));
const TheSecondaryMenu = defineAsyncComponent(() => import('./components/menu/TheSecondaryMenu.vue'));
const TheMainMenuMobile = defineAsyncComponent(() => import('./components/menu/TheMainMenuMobile.vue'));
const TheListContainer = defineAsyncComponent(() => import('./components/list/TheListContainer.vue'));
const TheChildrenSlider = defineAsyncComponent(() => import('./components/list/TheChildrenSlider.vue'));
const TheProductPrice = defineAsyncComponent(() => import('./components/product/TheProductPrice.vue'));
const TheProductAction = defineAsyncComponent(() => import('./components/product/TheProductAction.vue'));
const TheProductOptions = defineAsyncComponent(() => import('./components/product/TheProductOptions.vue'));
const TheProductQuantity = defineAsyncComponent(() => import('./components/product/TheProductQuantity.vue'));
const TheOneySimulations = defineAsyncComponent(() => import('./components/product/TheOneySimulations.vue'));
const TheProductVariation = defineAsyncComponent(() => import('./components/product/TheProductVariation.vue'));
const TheProductDescription = defineAsyncComponent(() => import('./components/product/TheProductDescription.vue'));
const TheProductReviewList = defineAsyncComponent(() => import('./components/product/review/TheProductReviewList.vue'));
const TheProductImagesCarousel = defineAsyncComponent(() => import('./components/product/TheProductImagesCarousel.vue'));
const TheInformationsDisclosure = defineAsyncComponent(() => import('./components/product/TheInformationsDisclosure.vue'));
const TheReconditionnedProducts = defineAsyncComponent(() => import('./components/product/TheReconditionnedProducts.vue'));
const TheProductReviewCreate = defineAsyncComponent(() => import('./components/product/review/TheProductReviewCreate.vue'));
const CartWrapper = defineAsyncComponent(() => import('./components/cart/cartWrapper.vue'));
const CartMenuWrapper = defineAsyncComponent(() => import('./components/cart/cartMenuWrapper.vue'));
const WishlistProduct = defineAsyncComponent(() => import('./components/wishlist/wishlistProduct.vue'));
const TheWishlistContainer = defineAsyncComponent(() => import('./components/wishlist/TheWishlistContainer.vue'));
const TheEmptyWishlist = defineAsyncComponent(() => import('./features/myAccount/wishlist/components/TheWishlistEmptyState.vue'));
const TheFaq = defineAsyncComponent(() => import('./components/faq/the-faq.vue'));
const TheIconWishlist = defineAsyncComponent(() => import('./components/wishlist/TheIconWishlist.vue'));
const TheNotification = defineAsyncComponent(() => import('./components/common/TheNotification.vue'));
const TheAftersaleCreate = defineAsyncComponent(() => import('./components/customer/aftersale/TheAftersaleCreate.vue'));
const TheAftersaleFollowUp = defineAsyncComponent(() => import('./components/customer/aftersale/TheAftersaleFollowUp.vue'));
const AftersaleListItem = defineAsyncComponent(() => import('./components/customer/aftersale/AftersaleListItem.vue'));
const AftersaleListEligibleItem = defineAsyncComponent(() => import('./components/customer/aftersale/AftersaleListEligibleItem.vue'));

/**
 * App creation
 *
 * Create vue pplication
 *
 * @see https://vuejs.org/guide/essentials/application
 */
const app = createApp();

/**
 * Component registration
 *
 * Register for blade files only otherwise use dynamic import
 *
 * @see https://vuejs.org/guide/components/registration
 */

/**
 * Icones
 */
app.component('v-icon-x', defineAsyncComponent(() => import('./icons/VIconX.vue')));
app.component('v-icon-cb', defineAsyncComponent(() => import('./icons/payment/VIconCB.vue')));
app.component('v-icon-faq', defineAsyncComponent(() => import('./icons/VIconFaq.vue')));
app.component('v-icon-help', defineAsyncComponent(() => import('./icons/VIconHelp.vue')));
app.component('v-icon-cart', defineAsyncComponent(() => import('./icons/VIconCart.vue')));
app.component('v-icon-user', defineAsyncComponent(() => import('./icons/VIconUser.vue')));
app.component('v-icon-star', defineAsyncComponent(() => import('./icons/VIconStar.vue')));
app.component('v-icon-mail', defineAsyncComponent(() => import('./icons/VIconMail.vue')));
app.component('v-icon-visa', defineAsyncComponent(() => import('./icons/payment/VIconVisa.vue')));
app.component('v-icon-oney', defineAsyncComponent(() => import('./icons/payment/VIconOney.vue')));
app.component('v-icon-check', defineAsyncComponent(() => import('./icons/VIconCheck.vue')));
app.component('v-icon-phone', defineAsyncComponent(() => import('./icons/VIconPhone.vue')));
app.component('v-icon-trash', defineAsyncComponent(() => import('./icons/VIconTrash.vue')));
app.component('v-icon-heart', defineAsyncComponent(() => import('./icons/VIconHeart.vue')));
app.component('v-icon-locked', defineAsyncComponent(() => import('./icons/VIconLocked.vue')));
app.component('v-logo-google', defineAsyncComponent(() => import('./icons/VLogoGoogle.vue')));
app.component('v-icon-photos', defineAsyncComponent(() => import('./icons/VIconPhotos.vue')));
app.component('v-icon-paypal', defineAsyncComponent(() => import('./icons/payment/VIconPaypal.vue')));
app.component('v-icon-search', defineAsyncComponent(() => import('./icons/VIconSearch.vue')));
app.component('v-icon-triman', defineAsyncComponent(() => import('./icons/VIconTriman.vue')));
app.component('v-icon-pencil', defineAsyncComponent(() => import('./icons/VIconPencil.vue')));
app.component('v-icon-filters', defineAsyncComponent(() => import('./icons/VIconFilters.vue')));
app.component('v-icon-youtube', defineAsyncComponent(() => import('./icons/VIconYoutube.vue')));
app.component('v-icon-twitter', defineAsyncComponent(() => import('./icons/VIconTwitter.vue')));
app.component('v-icon-facebook', defineAsyncComponent(() => import('./icons/VIconFacebook.vue')));
app.component('v-logo-facebook', defineAsyncComponent(() => import('./icons/VLogoFacebook.vue')));
app.component('v-icon-download', defineAsyncComponent(() => import('./icons/VIconDownload.vue')));
app.component('v-icon-chevron-r', defineAsyncComponent(() => import('./icons/VIconChevronR.vue')));
app.component('v-icon-scalapay', defineAsyncComponent(() => import('./icons/payment/VIconScalapay.vue')));
app.component('v-icon-chevron-l', defineAsyncComponent(() => import('./icons/VIconChevronL.vue')));
app.component('v-icon-chevron-d', defineAsyncComponent(() => import('./icons/VIconChevronD.vue')));
app.component('v-icon-oney_3x4x', defineAsyncComponent(() => import('./icons/payment/VIconOney3x4x.vue')));
app.component('v-payment-method', defineAsyncComponent(() => import('./components/footer/paymentMethod.vue')));
app.component('v-icon-chevron-u', defineAsyncComponent(() => import('./icons/VIconChevronU.vue')));
app.component('v-icon-instagram', defineAsyncComponent(() => import('./icons/VIconInstagram.vue')));
app.component('v-icon-pinterest', defineAsyncComponent(() => import('./icons/VIconPinterest.vue')));
app.component('v-icon-arrow-left', defineAsyncComponent(() => import('./icons/VIconArrowLeft.vue')));
app.component('v-icon-arrow-right', defineAsyncComponent(() => import('./icons/VIconArrowRight.vue')));
app.component('v-icon-bankwire_de', defineAsyncComponent(() => import('./icons/payment/VIconBankwireDe.vue')));
app.component('v-icon-bankwire_fr', defineAsyncComponent(() => import('./icons/payment/VIconBankwireFr.vue')));
app.component('v-icon-bankwire_es', defineAsyncComponent(() => import('./icons/payment/VIconBankwireEs.vue')));
app.component('v-icon-bankwire_it', defineAsyncComponent(() => import('./icons/payment/VIconBankwireIt.vue')));
app.component('v-icon-mastercard', defineAsyncComponent(() => import('./icons/payment/VIconMastercard.vue')));
app.component('v-icon-american_express', defineAsyncComponent(() => import('./icons/payment/VIconAmericanExpress.vue')));
app.component('v-icon-exclamation-triangle', defineAsyncComponent(() => import('./icons/VIconExclamationTriangle.vue')));

/**
 * Layout and related
 */
app.component('the-notification-banner', defineAsyncComponent(() => import('./components/notifications/TheNotificationBanner.vue')));

/**
 * Auth Pages and related
 */
app.component('the-auth', defineAsyncComponent(() => import('./features/auth/components/TheAuth.vue')));
app.component('the-password-forgotten', defineAsyncComponent(() => import('./features/auth/components/ThePasswordForgotten.vue')));
app.component('the-password-reset', defineAsyncComponent(() => import('./features/auth/components/ThePasswordReset.vue')));

/**
 * Customer Pages and related
 */
app.component('the-sidebar', defineAsyncComponent(() => import('./components/customer/TheSidebar.vue')));
app.component('the-newsletter', defineAsyncComponent(() => import('./features/myAccount/newsletter/components/TheNewsletter.vue')));
app.component('the-account-information', defineAsyncComponent(() => import('./features/myAccount/information/components/TheAccountInformation.vue')));
app.component('the-address-list', defineAsyncComponent(() => import('./features/myAccount/address/components/TheAddressList.vue')));
app.component('the-address-edit', defineAsyncComponent(() => import('./features/myAccount/address/components/TheAddressEdit.vue')));
app.component('the-address-create', defineAsyncComponent(() => import('./features/myAccount/address/components/TheAddressCreate.vue')));
app.component('the-wishlist', defineAsyncComponent(() => import('./features/myAccount/wishlist/components/TheWishlist.vue')));
app.component('the-fidelity-points-history', defineAsyncComponent(() => import('./features/myAccount/fidelityPoints/components/TheFidelityPointsHistory.vue')));
app.component('the-prepaid-history', defineAsyncComponent(() => import('./features/myAccount/prepaid/components/ThePrepaidHistory.vue')));
app.component('the-sponsorship', defineAsyncComponent(() => import('./features/sponsorship/components/TheSponsorship.vue')));

/**
 * Commons components
 */
app.component('the-search', TheSearch);
app.component('the-payment-return', ThePaymentReturn);
app.component('the-checkout-container', TheCheckoutContainer);
app.component('v-img', VImg);
app.component('alert', Alert);
app.component('slider', Slider);
app.component('help-icon', HelpIcon);
app.component('btn-submit', BtnSubmit);
app.component('breadcrumb', Breadcrumb);
app.component('input-error', InputError);
app.component('marketing-infos', MarketingInfos);
app.component('user-icon-login', UserIconLogin);
app.component('recently-consulted-container', RecentlyConsultedContainer);
app.component('disclosure', Disclosure);
app.component('simple-list', SimpleList);
app.component('shops-dropdown', ShopsDropdown);
app.component('newsletter-email', NewsletterEmail);
app.component('the-main-menu', TheMainMenu);
app.component('heart-icon-outline', HeartIcon);
app.component('the-secondary-menu', TheSecondaryMenu);
app.component('the-main-menu-mobile', TheMainMenuMobile);
app.component('the-list-container', TheListContainer);
app.component('the-children-slider', TheChildrenSlider);
app.component('the-product-price', TheProductPrice);
app.component('the-product-action', TheProductAction);
app.component('the-product-options', TheProductOptions);
app.component('the-product-quantity', TheProductQuantity);
app.component('the-oney-simulations', TheOneySimulations);
app.component('the-product-variation', TheProductVariation);
app.component('the-product-review-list', TheProductReviewList);
app.component('the-product-description', TheProductDescription);
app.component('the-product-review-create', TheProductReviewCreate);
app.component('the-product-images-carousel', TheProductImagesCarousel);
app.component('the-reconditionned-products', TheReconditionnedProducts);
app.component('the-informations-disclosure', TheInformationsDisclosure);
app.component('cart-wrapper', CartWrapper);
app.component('cart-menu-wrapper', CartMenuWrapper);
app.component('fidelity-points-usage-popover', FidelityPointsUsagePopover);
app.component('the-aftersale-create', TheAftersaleCreate);
app.component('the-aftersale-follow-up', TheAftersaleFollowUp);
app.component('aftersale-list-item', AftersaleListItem);
app.component('aftersale-list-eligible-item', AftersaleListEligibleItem);
app.component('wishlist-product', WishlistProduct);
app.component('the-wishlist-icon', TheIconWishlist);
app.component('the-empty-wishlist', TheEmptyWishlist);
app.component('the-wishlist-container', TheWishlistContainer);
app.component('the-notification', TheNotification);
app.component('the-faq', TheFaq);
app.component('pro-form', ProForm);

/**
 * Data provided by layouts
 *
 */
const dataApp = document.getElementById('app');
translate.setTranslations(dataApp.dataset.translations);
setLaravelRoutes(dataApp.dataset.jsRoutes);

/**
 * Sentry setup
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/vue/
 */
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
  integrations: [],
  debug: false,
});
if (dataApp.dataset.customer) {
  Sentry.setUser({ id: dataApp.dataset.customer });
}

/**
 * Axios setup
 *
 * @see https://axios-http.com/docs/interceptors
 */
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 && window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

/**
 * Provide values that can be injected in all descendant
 *
 * @see https://vuejs.org/api/application#app-provide
 */
app.provide('country', dataApp.dataset.country);
app.provide('translate', translate);
app.provide('locale', dataApp.dataset.locale);
app.provide('auth', dataApp.dataset.auth);
app.provide('fallbackLocale', dataApp.dataset.fallback_locale);

/**
 * Plugins installation
 *
 * @see https://vuejs.org/api/application#app-use
 */
app.use(LaravelRoutes);
app.use(VueQueryPlugin);

/**
 * Custom Directives
 *
 * @see https://vuejs.org/guide/reusability/custom-directives
 */
app.directive('lazy-load', lazyLoad);
app.directive('scroll-end', scrollEnd);

app.mount('#app');
